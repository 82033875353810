import React from "react"
import {Button, Carousel, Col, Container, Image, Nav, Row} from "react-bootstrap";
import {Link} from "gatsby";

const TeamCoachesFor = ({ teamCoachesFor }) => {

    return (
        <Container fluid style={{ backgroundColor: '#F0F0F0'}}>
            <Row>
                <Col>
                    <Container>
                        <Row>
                            <Col className="d-none d-md-flex flex-column align-items-center justify-content-center pb-5">
                                <h2 className="text-center fw-bold my-5" style={{ lineHeight: '3rem'}}>
                                    Team Coaches for
                                </h2>
                                {teamCoachesFor && teamCoachesFor.map((rowItems , index) => {
                                    return (
                                        <Row className={` ${index === 1 ? 'my-lg-5' : ''} w-100 d-flex flex-row flex-wrap align-items-center justify-content-evenly`}>
                                            {rowItems.map(item => {
                                                return (
                                                    <Col md={6} style={{ width: 'auto', position: 'relative' }} className="my-4 my-sm-0">
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-start rounded-circle shadow-lg"
                                                            style={{
                                                                background: 'white',
                                                                padding: '2em',
                                                                width: '20em',
                                                                height: '20em'
                                                            }}
                                                        >
                                                            <h4 className="px-4">{item.title}</h4>
                                                            <p className="px-4">{item.body}</p>
                                                        </div>
                                                        <Image
                                                            fluid
                                                            src={item.ellipseImage}
                                                            alt={item.title}
                                                            style={{ position: 'absolute', top: '-1em', right: '-1em', width: '7em' }}
                                                        />
                                                        <Nav.Link to={item.linkTo} as={Link}>
                                                            <Button
                                                                style={{
                                                                    borderRadius: "8px",
                                                                    backgroundColor: item.color,
                                                                    borderColor: item.color,
                                                                    color: 'white',
                                                                    width: '10em',
                                                                    position: 'absolute',
                                                                    bottom: '3em',
                                                                    right: '0'
                                                                }}
                                                            >
                                                                Learn more
                                                            </Button>
                                                        </Nav.Link>
                                                        {item.spots.map(spot => {
                                                            return  <div className="rounded-circle" style={spot.styles}/>
                                                        })}
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    )})}
                            </Col>
                            <Col className="d-flex d-md-none flex-column align-items-center justify-content-center pb-5">
                                <h2 className="text-center fw-bold my-5" style={{ lineHeight: '3rem'}}>
                                    Team Coaches for
                                </h2>
                                <Carousel
                                    className="mx-0"
                                    interval={5000}
                                    style={{ width: '100%'}}
                                >
                                    {teamCoachesFor && teamCoachesFor.flat().map((item , index) => {
                                        return (
                                            <Carousel.Item>
                                                <Row className={`w-100 d-flex flex-row flex-wrap align-items-center justify-content-evenly`}>
                                                    <Col md={12} style={{ width: 'auto', position: 'relative' }} className="my-4 my-sm-0">
                                                        <div
                                                            className="d-flex flex-column justify-content-center align-items-start rounded-circle shadow-lg"
                                                            style={{
                                                                background: 'white',
                                                                padding: '2em',
                                                                width: '20em',
                                                                height: '20em'
                                                            }}
                                                        >
                                                            <h4 className="px-4">{item.title}</h4>
                                                            <p className="px-4">{item.body}</p>
                                                        </div>
                                                        <Image
                                                            fluid
                                                            src={item.ellipseImage}
                                                            alt={item.title}
                                                            style={{ position: 'absolute', top: '-1em', right: '-1em', width: '7em' }}
                                                        />
                                                        <Nav.Link to={item.linkTo} as={Link}>
                                                            <Button
                                                                style={{
                                                                    borderRadius: "8px",
                                                                    backgroundColor: item.color,
                                                                    borderColor: item.color,
                                                                    color: 'white',
                                                                    width: '10em',
                                                                    position: 'absolute',
                                                                    bottom: '3em',
                                                                    right: '0'
                                                                }}
                                                            >
                                                                Learn more
                                                            </Button>
                                                        </Nav.Link>
                                                        {item.spots.map(spot => {
                                                            return  <div className="rounded-circle" style={spot.styles}/>
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Carousel.Item>
                                        )})}
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default TeamCoachesFor
